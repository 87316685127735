.main-page {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  font-family: "Helvetica", Arial, sans-serif;
  width: 100%;
  height: 100vh;
  align-items: center;
  overflow: hidden;
  min-width: 1640px;
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
  max-height: 1250px;
}

.main-page-inner {
  padding: 0;
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.main-page__header {
  margin-bottom: 40px;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;

  &-logo {
    max-width: 50px;
    margin-right: 24px;
  }
}

.main-page__header-title {
  max-width: 709px;
  font-size: 32px;
  font-weight: bold;
  color: #a7a9ac;
  line-height: 1.2;

  .main-page__header-subtitle {
    color: #a7a9ac;
    font-weight: 300;
    font-size: 32px;
    line-height: 112.7%;
  }
}

.main-page__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;

  &-text {
    margin-bottom: 24px;
    max-width: 700px;
    line-height: 112.7%;
    font-size: 48px;
    font-weight: bold;

    .highlight {
      color: #ff69b4;
    }
  }

  &-textsmall {
    font-size: 15px;
    line-height: 140%;
    margin-bottom: 24px;

    span {
      font-weight: bold !important;
    }
  }

  &-messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0;

    &-profile {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }

    &-content {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 10px;
      padding: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      &-play {
        width: 40px;
        height: 40px;
        background: #6a0dad;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.5em;
      }

      &-waveform {
        width: 200px;
        height: 40px;
        background: #6a0dad;
        margin: 10px 0;
        border-radius: 5px;
      }

      &-duration {
        font-size: 0.8em;
        color: #6a0dad;
      }
    }
  }
}

.main-page__footer {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  width: 100%;

  .main-page__footer-buttons {
    display: flex;

    @media (max-width: 1280px) {
      flex-direction: row;
    }
  }
}

.main-page__message {
  z-index: 2;
  align-items: center;
  display: flex;
  justify-content: center;

  &-blur {
    backdrop-filter: blur(1.5px);
    height: 300px;
    width: 300px;
    position: absolute;
    right: -200px;
    top: -300px;
    z-index: 10;
  }

  &-icon {
    position: relative;
    top: 99px;
    left: 113px;
    z-index: 1;
  }

  &-iconbig {
    z-index: 1;
  }

  &-bluricon {
    width: 246px;
    height: 508px;
    position: relative;
    right: 456px;
    z-index: 4;
  }
}

.main-page__bgpurple {
  position: absolute;
  top: -20vh;
  left: 55vw;
  height: 200vh;
  width: 60vw;
  transform: rotate(35deg) scale(1);
  background-color: rgba(127, 0, 227, 0.1);
}

.footer {
  width: 100%;
  position: absolute;
  bottom: 32px;

  &-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 1430px;
  }
  &-text {
    display: flex;
    flex-direction: column;
  }
  &-btn {
    padding: 6.5px 12px 5.5px 12px;
    border: 1px solid #8a25da;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    @media (min-width: 1281px) {
      left: calc(-1% - 100px);
      position: relative;
    }
    @media screen and (min-width: 1280px) and (max-width: 1500px) {
      left: -273px;
    }
  }
  &-btn a {
    font-size: 14px;
    font-family: Noto Sans;
    color: #000;
    font-family: "Noto Sans";
  }
  &-title {
    text-align: left;
    font-size: 11px;
    color: #8e8f93;
    font-family: "Noto Sans";
  }
  &-subtitle {
    text-align: left;
    font-size: 11px;
    color: #8e8f93;
    font-family: "Noto Sans";
  }
}

@media screen and (min-width: 1280px) and (max-width: 2600px) {
  .main-page-inner {
    margin: 395px auto;
  }
}

@media (min-width: 800px) {
  .main-page__content-textsmall-br {
    display: none;
  }
}

@media (max-width: 800px) {
  .main-page {
    flex-direction: column;
    padding: 10px;
    margin: 0 auto;

    &__header {
      margin-bottom: 32px;
    }

    &__header-title {
      font-size: 36px;
      display: flex;
      flex-direction: column;
    }

    &__message {
      top: auto;
      right: auto;
      position: relative;
      margin-top: 20px;
    }

    &__content {
      align-items: center;

      &-text {
        text-align: left;
      }
    }
  }
}

@media (min-width: 1023px) and (max-width: 1280px) {
  .main-page-inner {
    max-width: 80%;
  }

  .main-page__message {
    right: calc(15% - 98px);
  }
}
@media (min-width: 800px) and (max-width: 1280px) {
  .footer {
    width: 100%;
    position: absolute;
    bottom: 32px;

    &-inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

@media (min-width: 320px) and (max-width: 1280px) {
  .main-page {
    justify-content: flex-start;
    min-width: unset;

    .main-page-inner {
      margin: 64px auto;
      padding-left: 6px;
    }

    .main-page__content {
      align-items: flex-start;
    }

    .main-page__message {
      position: static;
    }

    .main-page__message-bluricon {
      right: 56px;
    }

    .main-page__message-icon {
      display: none;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .main-page {
    min-width: 844px;

    .main-page-inner {
      margin: 64px auto;
      padding-left: 32px;
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 800px) {
  .main-page__bgpurple {
    display: none;
  }

  .main-page__content-text {
    font-size: 24px;
  }

  .main-page__header-title {
    flex-direction: column;
    display: flex;
  }

  .main-page__content-text {
    max-width: 100% !important;
  }
}

@media (max-width: 480px) {
  .main-page__header-title {
    font-size: 20px;

    .main-page__header-subtitle {
      position: relative;
      bottom: -5px;
      font-size: 20px !important;
    }
  }

  .btn_wrapper :not(:first-child) {
    margin-left: 16px !important;
  }

  .main-page__content-textsmall {
    font-size: 15px;
  }

  .main-page__message-iconbig {
    max-width: 264px;
    right: -20px;
    position: relative;
  }

  .main-page__content-text {
    font-size: 24px;
    font-weight: bold;
    line-height: 112.7%;
    max-width: 182px;
    margin-top: 12px;
  }

  .main-page__footer-buttons {
    align-items: center;
  }
}

@media (max-width: 375px) {
  .main-page__header-title {
    line-height: 20px;
  }

  .main-page__message {
    margin-top: -20px;
  }
}

@media screen and (max-width: 800px) {
  .main-page__message-bluricon {
    position: unset;
  }

  .main-page {
    overflow: scroll;
  }

  .main-page__bgpurple {
    display: none;
  }
  .footer {
    position: relative;
    bottom: 32px;

    &-inner {
      flex-direction: column;
      margin-top: 64px;
      width: 100%;
      padding: 6px;
    }
    &-btn {
      margin-top: 24px;
      width: 100%;
      max-width: 350px;
    }
  }
}
