@import '../../styles/config.scss';

.btn_wrapper :not(:first-child) {
    margin-left: 24px;
}

@media screen and (max-width: 800px) {
    .btn_wrapper a {
        height: 40px;
    }
}

@media (max-width: 480px) {
    .btn_wrapper {
        &-appstore {
            max-width: 120px;
        }

        &-google {
            max-width: 135px;
        }
    }

}