@font-face {
    font-family: 'Helvetica';
    src: 
        url('../assets/fonts/helvetica/Helvetica-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: 
        url('../assets/fonts/helvetica/Helvetica-BoldOblique.ttf') format('truetype');
    font-weight: bold;
    font-style: oblique;
}

@font-face {
    font-family: 'Helvetica';
    src: 
        url('../assets/fonts/helvetica/helvetica-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: 
        url('../assets/fonts/helvetica/Helvetica-Oblique.ttf') format('truetype');
    font-weight: normal;
    font-style: oblique;
}

@font-face {
    font-family: 'Helvetica';
    src: 
        url('../assets/fonts/helvetica/Helvetica.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Sans';
    src: 
        url('../assets/fonts/noto-sans/NotoSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
